$orange: #fec23e;
$white: #FFF;
$boxShadow: #aaa;
$black: #000;

@mixin core() {
    display: inline-block;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 10px;

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &:visited {
        outline: none;
    }
}

@mixin colored_container($bgColor, $textColor, $borderColor, $bgHoverColor, $bgTextColor) {
    background: $bgColor;
    border: 2px solid $borderColor;
    color: $textColor;

    &:hover {
        background: $bgHoverColor;
        color: $bgTextColor;
        text-decoration: none;
        text-transform: none;
    }
}

@mixin shadow_container () {
    box-shadow: 3px 3px 10px 0 $boxShadow;
}

@mixin rounded_container() {
    border-radius: 0px !important;
}

@mixin lock($buttonColor) {
    position: relative;
    padding: 5px 10px 5px 38px;

    &:after {
        position: absolute;
        left: 10px;

        @if $buttonColor == 'yellow' {
            content: url(../../img/resources/icon-w.png);
        }
        @else if($buttonColor == 'white') {
            content: url(../../img/resources/icon-y.png);
        }
    }

    &:before {
        position: absolute;
        left: 10px;
		top: 2px;

        @if $buttonColor == 'yellow' {
            content: url(../../img/resources/icon-y.png);
        }
        @else if($buttonColor == 'white') {
            content: url(../../img/resources/icon-w.png);
        }
    }

    &:hover:after {
        display: none;
    }
}

.ph-signin {
    @include core();

    &.yellow {
        @include colored_container($orange, $black, $orange, $white, $black);

        @include lock('yellow');
    }

    &.white {
        @include colored_container($white, $black, $orange, $orange, $black);

        @include lock('white');
    }

    &.squared {
        @include rounded_container();
    }

    &.shadow {
        @include shadow_container();
    }
}
